<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-card no-body class="mb-0">
      <!-- Loading -->
      <template v-if="spinner === true">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <div class="text-center m-5">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
        </div>
      </template>

      <b-card v-else no-body class="mb-0 card-company-table">
        <!-- Search -->
        <div class="m-2">
          <b-row>
            <!-- <b-col md="6">
              <FCategoriesCom urlName="events" />
            </b-col> -->

            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <!-- <router-link
                  :to="{
                    name: 'add-new',
                    query: {
                      categories: JSON.stringify(categories),
                      isService: false,
                      isEvent: true,
                      isNews: false,
                      isEdit: false,
                    },
                  }"
                >
                  <b-button variant="primary">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="text-nowrap">Add New Event</span>
                  </b-button>
                </router-link> -->

                <router-link :to="{name: 'CreateEvent'}">
                  <b-button variant="primary">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="text-nowrap">Add New Event</span>
                  </b-button>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>

        <vue-good-table
          :columns="fields"
          :rows="eventsDatas"
          :theme="skin == 'dark' ? 'nocturnal' : ''"
          :pagination-options="{enabled : true}"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'categories'">
              <span
                style="font-size: 0.9rem; margin-right: 5px"
                v-for="c in props.row.categories"
                :key="c.id"
              >
                {{ c.name }}
              </span>
            </span>

            <span v-else-if="props.column.field == 'actions'">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'event-details', query: { id: props.row.id }, }">
                    <router-link :to="{ name: 'event-details', query: { id: props.row.id }, }" class="dropdown-item-con">
                      <feather-icon icon="BookOpenIcon" />
                      <span class="align-middle ml-50">Details</span>
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item :to="{name : 'EditEvent' , params : {id : props.row.id}}">
                    <feather-icon icon="EditIcon" />
                    <router-link :to="{name : 'EditEvent' , params : {id : props.row.id}}" class="align-middle ml-50">Edit</router-link>
                  </b-dropdown-item>
                  <b-dropdown-item @click="delEventData(props.row.id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
            </span>

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div style="margin: 1rem 0rem;display: flex;justify-content: end;">
              <b-pagination
                v-if="meta.current_page"
                v-model="meta.current_page"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                @change="changePage"
              ></b-pagination>
            </div>
          </template>
        </vue-good-table>

        <!-- Events Table -->
        <!-- <Table :fields="fields" :items="eventsDatas" :isEvent="true" routeName="event-details"
          :fetchDatas="fetchEventsDatas" pageName="events" :pageNum="pageNum" @delFunc="delEventData" /> -->
      </b-card>
    </b-card>
  </div>
</template>
  
<script>
import store from "@/store";
import Table from "../components/Table.vue";
import { useRouter } from "@core/utils/utils";
import MyPhotoUpload from "../components/MyPhotoUpload.vue";
import FCategoriesCom from "../components/FCategoriesCom.vue";
import router from "@/router";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import {
  ref,
  reactive,
  computed,
  watch,
  onMounted
  // ref, watch, computed, onUnmounted,
} from "@vue/composition-api";
import {
  BPagination,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BPagination,
    VueGoodTable,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BTabs,
    BTab,
    BFormCheckbox,
    BFormDatepicker,
    Table,
    MyPhotoUpload,
    FCategoriesCom,
  },
  setup() {
    const skin = ref(localStorage.getItem('vuexy-skin'));
    onMounted(() => {
        window.addEventListener('vuexy-skin-localstorage-changed', (event) => {
          skin.value = event.detail.storage;
        });
    });
    const { route, router } = useRouter();
    const fields = [
      { sortable : false, field: "id", label: "#" },
      { sortable : false, field: "title", label: "title" },
      { sortable : false, field: "sub_title", label: "sub title" },
      { sortable : false, field: "status", label: "publish" },
      { sortable : false, field: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const pageNum = ref(1);
    const eventsDatas = ref([]);
    const categories = ref([]);
    const meta = ref({});

    //Datas Fetching
    const fetchEventsDatas = () => {
      spinner.value = true;
      if (router.app._route.query.page) {
        pageNum.value = router.app._route.query.page;
      }
      if (router.app._route.query.category_id) {
        pageNum.value = router.app._route.query.category_id;
      }
      let queryKey = Object.keys(router.app._route.query)[0];
      store
        .dispatch(
          "app-mypages/fetchDatas",
          `events?${queryKey}=${pageNum.value}`
        )
        .then((response) => {
          eventsDatas.value = response.data.data;
          meta.value = response.data.meta
          spinner.value = false;
        });
    };
    fetchEventsDatas();
    const changePage = (e) => {
      router.push({name : 'apps-events' , query : { ...router.app._route.query , page : e}})
      pageNum.value = e;
      fetchEventsDatas();
    }
    // Route Params
    const routeParams = computed(() => route.value.params);
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchEventsDatas();
    });

    const fetchCategories = () => {
      store
        .dispatch("app-mypages/fetchDatas", "categories")
        .then((response) => {
          // categories.value = response.data.data;
          categories.value = response.data.data.filter(
            (te) => te.category_type === "event"
          );
        });
    };
    fetchCategories();

    // Data Deleting
    const delEventData = (id) => {
      spinner.value = true;
      store
        .dispatch("app-mypages/delData", { path: "events", id })
        .then((response) => {
          fetchEventsDatas();
          spinner.value = false;
        });
    };

    return {
      skin,
      fields,
      spinner,
      pageNum,
      eventsDatas,
      categories,
      fetchEventsDatas,
      delEventData,
      meta,
      changePage

    };
  },
};
</script>
  